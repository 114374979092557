import {http} from './config';

export default {
    uploadDocument: (data) => {
        return http.post('api/v1/arquivos/upload', data);
    },
    uploadDocumentPublic: (data, companyId) => {
        return http.post('api/v1/publica/arquivos/upload', data,{headers: {'company-id': companyId}});
    },
    download: (id) => {
        return http.get(`api/v1/arquivos/download/${id}`, {responseType: 'blob'});
    },
    fileDelete: (id) => {
        return http.delete(`api/v1/arquivos/${id}`);
    },
    index: (page = 1, filter) => {
        return http.get('api/v1/arquivos?page=' + page, {params: filter});
    },
    hasPlanDrive: (companyId) => {
        return http.get('api/v1/permissaoDrive', {headers: {'company-id': companyId}});
    }
}